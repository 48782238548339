import CryptoJS from 'crypto-js';
const download = require('downloadjs');

interface IDownload {
	payload: string;
	name: string;
}

export const uploadToFileServer = (item: any): Promise<any> => {
	return new Promise((resolve, reject) => {
		const timestamp = (Date.now() / 1000 || 0).toString();
		const hashString = `timestamp=${timestamp}${process.env.REACT_APP_CLOUDINARY_API_SECRET}`;
		const uploadUrl = `${process.env.REACT_APP_FILE_SERVER_URL}/misc/upload`;
		const formdata = new FormData();
		const encrypted = CryptoJS.SHA1(hashString).toString();
		const xhr = new XMLHttpRequest();

		formdata.append('file', item);
		formdata.append('resource_type', 'raw');
		formdata.append('timestamp', timestamp);
		formdata.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY || '');
		formdata.append('signature', encrypted);

		xhr.open('POST', uploadUrl, true);
		xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
		xhr.send(formdata);

		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4 && xhr.status === 200) {
				const response = JSON.parse(xhr.responseText);
				response.url = `${process.env.REACT_APP_FILE_SERVER_URL}/misc/image?file=${response.data}`;
				response.secure_url = response.url;
				resolve(response);
			} else if (xhr.status !== 200) {
				reject(xhr);
			}
		};
	});
};

export const downloadImage = ({ payload, name }: IDownload) => {
	download(payload, `${name || 'file'}.png`, 'image/png');
};
