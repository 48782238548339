import React from 'react';

// Material components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Resources

// Assets
// import { useStyles } from './styles';

interface IModalProps {
	onView: boolean;
	title: string;
	description?: string;
	width?: 'md' | 'lg' | 'xl' | 'sm' | 'xs';
	onClose?: () => void;
	onSave?: () => void;
	onDownloadReport?: () => void;
	textCancel?: string;
	children: React.ReactChild;
}

const Modal = (props: IModalProps) => {
	const {
		onView,
		onClose,
		onSave,
		onDownloadReport,
		title,
		description,
		children,
		width,
		textCancel,
	} = props;
	return (
		<Dialog open={onView} maxWidth={width || 'md'} fullWidth onClose={onClose}>
			<DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
				{title}
				{onDownloadReport && (
					<Button
						variant="contained"
						color="primary"
						onClick={onDownloadReport}
					>
						Download Report
					</Button>
				)}
			</DialogTitle>
			<DialogContent>
				{description && <DialogContentText>{description}</DialogContentText>}
				{children}
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={onClose}>
					{textCancel ? textCancel : 'Cancel'}
				</Button>
				{onSave && (
					<Button color="primary" onClick={onSave}>
						Save
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default Modal;
