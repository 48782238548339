import { useState, useEffect } from 'react';

// Resources
import { getHoles } from '../api/holes';
import { IHole } from '../interfaces/hole';

export const useHole = () => {
	const [holes, setHoles] = useState<IHole[]>([]);
	const [hole, setHoleState] = useState<IHole>({} as IHole);

	useEffect(() => {
		const holeFlag = localStorage.getItem('hole');
		getHoles().then(res => {
			setHoles(res || []);
			setHoleState(holeFlag ? JSON.parse(holeFlag) : {});
		});
	}, []);

	const setHole = (holeID: string | number): void => {
		const holeV: IHole = holes.find(e => e.id === holeID) || ({} as IHole);
		localStorage.setItem('hole', JSON.stringify(holeV));
		setHoleState(holeV);
	};

	return {
		setHole,
		hole,
		holes,
	};
};
