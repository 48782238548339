import { makeStyles, createStyles } from '@mui/styles';
// import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(() =>
	createStyles({
		root: {
			display: 'flex',
			flexGrow: 1,
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			height: '100vh',
		},
		containerForm: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			width: '45%',
			padding: 40,
		},
		textField: {
			height: 50,
			marginTop: '20px !important',
		},
		btnLogin: {
			width: '100%',
			marginTop: '20px !important',
		},
	}),
);
