/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';

// Material components
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// Resources
import { IGroup } from '../../interfaces/group';
import { IAlert } from '../../interfaces/alert';
import { uploadToFileServer } from '../../utils';
import { getGroups, groupsAPI } from '../../api/groups';

// Assets
import { useStyles } from './styles';

const Groups = () => {
	const [group, setGroup] = useState<IGroup>({} as IGroup);
	const [alert, setAlert] = useState<IAlert>({
		visible: false,
		message: '',
		severity: 'success',
	});
	const [imgName, setImgName] = useState('');
	const [edit, setEdit] = useState(false);
	const [groups, setGroups] = useState<IGroup[]>([]);
	const classes = useStyles();

	useEffect(() => {
		_getDataFromApi();
	}, []);

	const _getDataFromApi = (): void => {
		getGroups().then(res => {
			setGroups(res.reverse() || []);
		});
	};

	const _handleSaveGroup = (): void => {
		if (!group.name) {
			return setAlert({
				visible: true,
				severity: 'warning',
				message: 'Please add a name to the group',
			});
		}

		let promise: Promise<IGroup>;

		if (edit) {
			promise = groupsAPI.put(group.id, group);
		} else {
			promise = groupsAPI.post('', group);
		}

		promise
			.then(res => {
				if (res) {
					setAlert({
						visible: true,
						severity: 'success',
						message: 'The group has been saved successfully',
					});
					if (edit) {
						const groupFlag = localStorage.getItem('group');
						const groupParsed = groupFlag
							? JSON.parse(groupFlag)
							: ({} as IGroup);
						if (groupParsed.id === group.id) {
							localStorage.setItem('group', JSON.stringify(group));
						}
					}
					_getDataFromApi();
				}
			})
			.catch(() => {
				setAlert({
					visible: true,
					severity: 'error',
					message: 'Error, the group has not been saved',
				});
			})
			.finally(() => _cleanStates());
	};

	const _cleanStates = (): void => {
		setGroup({} as IGroup);
		setImgName('');
		setEdit(false);
	};

	const _uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			const fileToAdd: File = event?.target?.files[0];
			uploadToFileServer(fileToAdd).then(file => {
				setImgName(fileToAdd.name || '');
				setGroup({ ...group, image: file.url });
			});
		}
	};

	return (
		<div className={classes.containerGroup}>
			<div className={classes.containerFormGroup}>
				<TextField
					id="name"
					label="Name"
					className={classes.textFieldGroup}
					variant="outlined"
					fullWidth
					value={group.name || ''}
					onChange={e => setGroup({ ...group, name: e.target.value })}
				/>
				<TextField
					id="file_name"
					label="File Name"
					className={classes.textFieldGroup}
					variant="outlined"
					disabled
					value={imgName || ''}
				/>
				<Button
					variant="contained"
					className={classes.addImageGroup}
					component="label"
				>
					Upload File
					<input type="file" hidden onChange={_uploadFile} />
				</Button>
				<Button
					className={classes.btnSaveGroup}
					variant="contained"
					onClick={_handleSaveGroup}
				>
					{edit ? 'Edit' : 'Save'}
				</Button>
				{edit && (
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						className={classes.btnCleanStates}
						aria-label="menu"
						sx={{ mr: 2 }}
						onClick={_cleanStates}
					>
						<CancelIcon />
					</IconButton>
				)}
			</div>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead className={classes.headerTable}>
						<TableRow>
							<TableCell className={classes.headerTextTable}>Name</TableCell>
							<TableCell
								size="small"
								className={classes.headerTextTableActions}
							>
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{groups.map(row => (
							<TableRow
								key={row.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell align="left">{row.name}</TableCell>
								<TableCell align="left">
									<IconButton
										size="large"
										edge="start"
										color="inherit"
										aria-label="menu"
										sx={{ mr: 2 }}
										onClick={() => {
											setGroup({ ...row });
											setEdit(true);
										}}
									>
										<EditIcon />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={alert.visible}
				onClose={() => setAlert({ ...alert, visible: false })}
			>
				<MuiAlert
					elevation={6}
					severity={alert.severity}
					variant="filled"
					onClose={() => setAlert({ ...alert, visible: false })}
					sx={{ width: '100%' }}
				>
					{alert.message}
				</MuiAlert>
			</Snackbar>
		</div>
	);
};

export default Groups;
