/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

// Material compnents
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';

// Components
import NavBar from '../Navbar';

// Resources
import { useLocation, Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

// Assets
import { useStyles } from './styles';
import { config } from '../../config';

interface IDrawerPersist {
	children: React.ReactElement;
	isAuthenticated?: boolean;
	// menu: IMenuOption[][];
}

const DrawerPersist = (props: IDrawerPersist) => {
	const [menuActive, setMenuActive] = useState('');
	const [menuName, setMenuName] = useState('');
	const location = useLocation();
	const classes = useStyles();
	const history = useHistory();
	const { getUserLogged } = useAuth();
	const { children } = props;

	useEffect(() => {
		const isLogged = getUserLogged();
		if (!isLogged) {
			history.push('/login');
		}
	}, []);

	useEffect(() => {
		const path = location.pathname.split('/');
		const menu = config.routes.find(item => item.route === path[1]);

		_handleSetMenu(menu?.name || '');
		_handleMenuSelected(path[1] ?? '');
	}, [location]);

	const _handleMenuSelected = (val: string) => {
		setMenuActive(val);
	};

	const _handleSetMenu = (val: string) => {
		setMenuName(val);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<NavBar title={menuName} />
			<Drawer className={classes.drawer} variant="permanent">
				<Toolbar />
				<List>
					{config.routes.map((item, index) => (
						<ListItem
							button
							component={Link}
							to={'/' + item.route}
							selected={item.route === menuActive}
							key={index}
							className={classes.selectedMenu}
						>
							<ListItemIcon>
								<Icon>{item.icon}</Icon>
							</ListItemIcon>
							<ListItemText primary={item.name} />
						</ListItem>
					))}
				</List>
				<Divider />
			</Drawer>
			<main className={classes.content}>
				<Toolbar />
				{children}
			</main>
		</Box>
	);
};

export default DrawerPersist;
