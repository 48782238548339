/* eslint-disable no-undef */
import React, { useState } from 'react';

// Material components
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';

// Components

// Resources
import { useHistory } from 'react-router-dom';
import { IAlert } from '../../interfaces/alert';
import { useAuth } from '../../hooks/useAuth';
import { IUser } from '../../interfaces/user';

// Assets
import { useStyles } from './styles';

const Login = () => {
	const [user, setUser] = useState({} as IUser);
	const [alert, setAlert] = useState<IAlert>({
		visible: false,
		message: '',
		severity: 'success',
	});

	const history = useHistory();
	const { setLogin } = useAuth();
	const classes = useStyles();

	const _handleLogin = (e: any): void => {
		e.preventDefault();
		setLogin({ ...user }).then(response => {
			if (response.error_message) {
				setAlert({
					visible: true,
					message: response.error_message,
					severity: 'error',
				});
			} else {
				localStorage.setItem('user', JSON.stringify(response.user));
				localStorage.setItem('token', JSON.stringify(response.Authorization));
				history.push('/hourly-report');
			}
		});
	};

	return (
		<div className={classes.root}>
			<Paper className={classes.containerForm} elevation={4}>
				<Typography variant="h3" component="h3">
					L O G I N
				</Typography>
				<form onSubmit={_handleLogin}>
					<TextField
						id="username"
						label="Username"
						className={classes.textField}
						variant="standard"
						fullWidth
						value={user.email || ''}
						onChange={e => setUser({ ...user, email: e.target.value })}
					/>
					<TextField
						id="password"
						label="Password"
						className={classes.textField}
						variant="standard"
						fullWidth
						type="password"
						value={user.password || ''}
						onKeyDown={e => (e.code === 'Enter' ? _handleLogin : null)}
						onChange={e => setUser({ ...user, password: e.target.value })}
					/>
					<Button
						type="submit"
						className={classes.btnLogin}
						variant="contained"
						onClick={e => _handleLogin(e)}
					>
						L O G I N
					</Button>
				</form>
			</Paper>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={alert.visible}
				onClose={() => setAlert({ ...alert, visible: false })}
			>
				<MuiAlert
					elevation={6}
					severity={alert.severity}
					variant="filled"
					onClose={() => setAlert({ ...alert, visible: false })}
					sx={{ width: '100%' }}
				>
					{alert.message}
				</MuiAlert>
			</Snackbar>
		</div>
	);
};

export default Login;
