import { IUser } from '../interfaces/user';
import API from './base';

export const userAPI = new API('/users');

export const getUsers = (params?: any): Promise<IUser[]> => {
	return userAPI
		.post<null, IUser[]>('search', params)
		.then(response => response);
};
