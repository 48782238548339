import React, { useState } from 'react';

// Material components
import Button from '@mui/material/Button';

// Assets
import { useStyles } from './styles';

interface ITab {
	name: string;
	component: React.ReactElement;
}
interface ITabsProps {
	tabs: ITab[];
}

const Tabs = (props: ITabsProps) => {
	const [active, setActive] = useState(0);
	const classes = useStyles();
	const { tabs } = props;

	return (
		<div className={classes.container}>
			<div className={classes.containerTab}>
				{(tabs || []).map((item, index) => (
					<Button
						key={index}
						variant={active === index ? 'contained' : 'outlined'}
						className={classes.buttonsMargin}
						onClick={() => setActive(index)}
					>
						{item.name}
					</Button>
				))}
			</div>
			<div className={classes.containerComponent}>{tabs[active].component}</div>
		</div>
	);
};

export default Tabs;
