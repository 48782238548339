/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

// Material components
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import TimePicker from '@mui/lab/TimePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TablePagination from '@mui/material/TablePagination';

// Components
import Modal from '../../components/Modal';
import ModalLoading from '../../components/ModalLoading';

// Resources
import { IRun } from '../../interfaces/run';
import { IAlert } from '../../interfaces/alert';
import { useGroup } from '../../hooks/useGroup';
import { useShift } from '../../hooks/useShift';
import { useHole } from '../../hooks/useHole';
import { createReport } from '../../api/reports';
import { getRunReports, runAPI } from '../../api/run';
import { config } from '../../config';
import { downloadImage } from '../../utils';

// Assets
import { useStyles } from './styles';

// const a = `
// Avance: 9.0metros
// Distancia: 3.00mts Perforado: 3.00mts
// Nucleo Recuperado: 3.00 mts
// Tipo de Roca: compacta
// Profundidad de Barreno: 148.90mts
// Mezcla de Aditivos: 1/4
// Prueba de presion maxima del agua: 900 psi
// Prueba de flujo de agua: 60 LxM
// Presion de agua perforando: 150 psi
// Tubo interior, suelto o con el pescador: suelto
// Tiempo para que llegue el interior al barril: 8.20minutos
// Presion de agua en el aire: 50
// Retorno de agua: 20%
// Rotacion: rapida
// Torque de rotacion en el aire: 2200
// Torque de rotacion perforando: 3100
// Tiempo de perforar la corrida: 18minutos
// Tiempo total de la corrida: 23minutos
// Temp de motor: 180°F
// Temp Bomba Hid: 116.5°F
// Temp caja de Engranes: 122.6°F
// Empuje: 900
// Termino la corrida por?: Completo
// Nucleo en el barreno o barril?: Ninguno`;

const RunReport = () => {
	const [run, setRun] = useState<IRun>({} as IRun);
	const [text, setText] = useState('');
	const [loading, setLoading] = useState(false);
	const [lanText, setLanText] = useState(false);
	const [modalForm, setModalForm] = useState(false);
	const [modalReport, setModalReport] = useState(false);
	const [report, setReport] = useState('');
	const [runArr, setRunArr] = useState<IRun[]>([]);
	const [alert, setAlert] = useState<IAlert>({
		visible: false,
		message: '',
		severity: 'success',
	});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const { groups, group, setGroup } = useGroup();
	const { shifts, shift, setShift } = useShift();
	const { holes, hole, setHole } = useHole();
	const classes = useStyles();

	useEffect(() => {
		_getDataFromApi();
	}, []);

	const _getDataFromApi = (): void => {
		getRunReports({
			includes: [
				{
					model: 'group',
					attributes: ['id', 'name', 'image'],
				},
				{
					model: 'hole',
				},
			],
		}).then(res => {
			if (Array.isArray(res)) {
				setRunArr(res.reverse() || []);
			}
		});
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const _handleGetParameters = () => {
		let runFlag = { ...run };
		if (!text) {
			return setAlert({
				visible: true,
				severity: 'warning',
				message: 'Please add a comments',
			});
		}
		const lang = lanText ? 'en' : 'es';
		text.split('\n').forEach(ele => {
			if (ele.split(':').length > 1) {
				const key = ele.split(':')[0].toLowerCase().trim();
				const value = ele.split(':')[1].trim();
				const keyFlag = config.runKeys[lang][key];
				runFlag = { ...runFlag, [keyFlag]: value };
			}
		});
		setRun(runFlag);
	};

	const onPrintReport = (item: IRun) => {
		const payload = {
			idReport: 55,
			format: 'image',
			payload: {
				Report: {
					...item,
					date: moment(item.date).format('MM/DD/YYYY'),
					time: moment(item.time).format('HH:mm'),
				},
				Options: { Language: 'es' },
			},
		};
		setLoading(true);
		createReport<IRun>(payload).then(({ data }) => {
			setReport(
				`data:image/png;headers=filename%3D${
					item.date || 'download_file'
				};base64,${escape(data)}` || '',
			);
			setModalReport(true);
			setLoading(false);
		});
		console.log(report);
	};

	const _handleSaveRun = (): void => {
		// if (!hole.hole_number) {
		// 	return setAlert({
		// 		visible: true,
		// 		severity: 'warning',
		// 		message: 'Please add a hole number to the hole',
		// 	});
		// }

		// if (!station.id) {
		// 	return setAlert({
		// 		visible: true,
		// 		severity: 'warning',
		// 		message: 'Please select a station',
		// 	});
		// }
		const runFlag: IRun = {
			...run,
			shift,
			time: run.time
				? moment(run.time).format('MM/DD/YYYY HH:mm:ss')
				: moment().format('MM/DD/YYYY HH:mm:ss'),
			date: run.date || moment().format('MM/DD/YYYY'),
			group_id: group.id,
			hole_id: hole.id,
		};
		console.log(runFlag);
		setLoading(true);
		runAPI
			.post('', runFlag)
			.then(res => {
				console.log({ res });
				if (res) {
					setAlert({
						visible: true,
						severity: 'success',
						message: 'The run report has been saved successfully',
					});
					setModalForm(false);
					setRun({} as IRun);
					setText('');
					_getDataFromApi();
				}
			})
			.catch(() => {
				setAlert({
					visible: true,
					severity: 'error',
					message: 'Error, the run report has not been saved',
				});
			})
			.finally(() => setLoading(false));
	};

	const _handleCleanData = (): void => {
		setModalForm(false);
		setRun({} as IRun);
		setText('');
	};

	return (
		<div className={classes.root}>
			<div className={classes.containerButtonAdd}>
				<FormControl fullWidth className={classes.padding5}>
					<InputLabel id="group">Groups</InputLabel>
					<Select
						labelId="group"
						id="group"
						value={group.id || ''}
						label="Group"
						onChange={e => setGroup(e.target.value)}
					>
						{(groups || []).map((item, index) => (
							<MenuItem key={index} value={item.id}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth className={classes.padding5}>
					<InputLabel id="shift">Shifts</InputLabel>
					<Select
						labelId="shift"
						id="shift"
						value={shift || ''}
						label="Shift"
						onChange={e => setShift(e.target.value)}
					>
						{(shifts || []).map((item, index) => (
							<MenuItem key={index} value={item}>
								{item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth className={classes.padding5}>
					<InputLabel id="hole">Holes</InputLabel>
					<Select
						labelId="hole"
						id="hole"
						value={hole.id || ''}
						label="Hole"
						onChange={e => setHole(e.target.value)}
					>
						{(holes || []).map((item, index) => (
							<MenuItem key={index} value={item.id}>
								{item.hole_number}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			<div className={classes.containerButtonAdd}>
				<Button
					className={classes.btnAdd}
					variant="contained"
					onClick={() => setModalForm(true)}
				>
					Add Run
				</Button>
			</div>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead className={classes.headerTable}>
						<TableRow>
							<TableCell className={classes.headerTextTable}>Group</TableCell>
							<TableCell className={classes.headerTextTable}>Shift</TableCell>
							<TableCell className={classes.headerTextTable}>Time</TableCell>
							<TableCell className={classes.headerTextTable}>Date</TableCell>
							<TableCell
								size="small"
								className={classes.headerTextTableActions}
							>
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(runArr || []).map(row => (
							<TableRow
								key={row.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell align="left">{row.group?.name}</TableCell>
								<TableCell align="left">{row.shift}</TableCell>
								<TableCell align="left">
									{moment(row.time).format('HH:mm')}
								</TableCell>
								<TableCell align="left">
									{moment(row.date).format('MM/DD/YYYY')}
								</TableCell>
								<TableCell align="left">
									<IconButton
										size="large"
										edge="start"
										color="inherit"
										aria-label="delete"
										sx={{ mr: 2 }}
										onClick={() => onPrintReport(row)}
									>
										<PrintIcon />
									</IconButton>
									{/* <IconButton
										size="large"
										edge="start"
										color="inherit"
										aria-label="delete"
										sx={{ mr: 2 }}
									>
										<DeleteIcon />
									</IconButton> */}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[20, 50, 100]}
				component="div"
				count={runArr.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			<Modal
				onView={modalForm}
				width="xl"
				title="Run Form"
				onClose={_handleCleanData}
				onSave={_handleSaveRun}
			>
				<>
					<Grid
						container
						rowSpacing={3}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							<Typography variant="h6">Group</Typography>
							<Typography variant="subtitle1" color="primary">
								{group?.name}
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="h6">Shift</Typography>
							<Typography variant="subtitle1" color="primary">
								{shift}
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="h6">Hole Number</Typography>
							<Typography variant="subtitle1" color="primary">
								{hole?.hole_number}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						container
						direction="row"
						rowSpacing={3}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={6}>
							<Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} xs={12}>
								<Grid item xs={6}>
									<FormControlLabel
										onChange={() => setLanText(!lanText)}
										control={<Switch defaultChecked />}
										label={lanText ? 'English' : 'Spanish'}
									/>
								</Grid>
								<Grid item display="flex" justifyContent="flex-end" xs={6}>
									<Button variant="contained" onClick={_handleGetParameters}>
										Generate
									</Button>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="comments"
										label="Comments"
										className={classes.textFieldComments}
										variant="standard"
										fullWidth
										multiline
										rows={25}
										value={text || ''}
										onChange={e => setText(e.target.value)}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} xs={12}>
								<Grid item xs={6}>
									<DesktopDatePicker
										label="Date"
										inputFormat="MM/DD/YYYY"
										value={run.date || new Date()}
										onChange={e =>
											setRun({
												...run,
												date: moment(e).format('MM/DD/YYYY'),
											})
										}
										renderInput={(params: any) => (
											<TextField fullWidth variant="standard" {...params} />
										)}
									/>
								</Grid>
								<Grid item xs={6}>
									<TimePicker
										label="Time"
										value={run.time || new Date()}
										onChange={e => setRun({ ...run, time: e })}
										renderInput={(params: any) => (
											<TextField fullWidth variant="standard" {...params} />
										)}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="shift_production"
										label="Shift production"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.shift_production || ''}
										onChange={e =>
											setRun({ ...run, shift_production: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="distance_drilled"
										label="Distance drilled"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.distance_drilled || ''}
										onChange={e =>
											setRun({ ...run, distance_drilled: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="core_recovered"
										label="Core recovered"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.core_recovered || ''}
										onChange={e =>
											setRun({ ...run, core_recovered: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="core_type"
										label="Core type"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.core_type || ''}
										onChange={e =>
											setRun({ ...run, core_type: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="hole_depth"
										label="Hole depth"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.hole_depth || ''}
										onChange={e =>
											setRun({ ...run, hole_depth: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="additives_mix"
										label="Additives mix"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.additives_mix || ''}
										onChange={e =>
											setRun({ ...run, additives_mix: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="maximum_water"
										label="Maximum water pressure test"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.maximum_water || ''}
										onChange={e =>
											setRun({ ...run, maximum_water: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="water_flow"
										label="Water flow test"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.water_flow || ''}
										onChange={e =>
											setRun({ ...run, water_flow: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="water_pressure"
										label="Water pressure while drilling"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.water_pressure || ''}
										onChange={e =>
											setRun({ ...run, water_pressure: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="inner_tube"
										label="Inner tube lowered or dropped"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.inner_tube || ''}
										onChange={e =>
											setRun({ ...run, inner_tube: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="time_inner_tube"
										label="Time for inner tube to reach the barrel"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.time_inner_tube || ''}
										onChange={e =>
											setRun({ ...run, time_inner_tube: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="water_pressure_drilling"
										label="Water pressure before drilling"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.water_pressure_drilling || ''}
										onChange={e =>
											setRun({
												...run,
												water_pressure_drilling: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="water_return"
										label="Water return out of hole"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.water_return || ''}
										onChange={e =>
											setRun({ ...run, water_return: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="drilling_rotation"
										label="Drilling rotation in high or low"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.drilling_rotation || ''}
										onChange={e =>
											setRun({ ...run, drilling_rotation: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="rotation_torque_bottom"
										label="Rotation torque off of bottom"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.rotation_torque_bottom || ''}
										onChange={e =>
											setRun({ ...run, rotation_torque_bottom: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="rotation_torque_drilling"
										label="Rotation torque while drilling"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.rotation_torque_drilling || ''}
										onChange={e =>
											setRun({
												...run,
												rotation_torque_drilling: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="time_drill"
										label="Time to drill the run"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.time_drill || ''}
										onChange={e =>
											setRun({ ...run, time_drill: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="total_time"
										label="Total time for the drill run"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.total_time || ''}
										onChange={e =>
											setRun({ ...run, total_time: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="temp_engine"
										label="Temp. engine"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.temp_engine || ''}
										onChange={e =>
											setRun({ ...run, temp_engine: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="temp_hydro"
										label="Temp. hydro pump"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.temp_hydro || ''}
										onChange={e =>
											setRun({ ...run, temp_hydro: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="temp_gear"
										label="Temp. gear box"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.temp_gear || ''}
										onChange={e =>
											setRun({ ...run, temp_gear: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="rotation_unit"
										label="Rotation unit push pressure"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.rotation_unit || ''}
										onChange={e =>
											setRun({ ...run, rotation_unit: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="run_finished"
										label="Why the run finished"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.run_finished || ''}
										onChange={e =>
											setRun({ ...run, run_finished: e.target.value })
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="slipped_core"
										label="Slipped core in the hole or barrel"
										className={classes.textField}
										variant="standard"
										fullWidth
										value={run.slipped_core || ''}
										onChange={e =>
											setRun({ ...run, slipped_core: e.target.value })
										}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</>
			</Modal>
			<Modal
				onView={modalReport}
				title="Run Report"
				width="md"
				textCancel="Close"
				onClose={() => setModalReport(false)}
				onDownloadReport={() =>
					downloadImage({ payload: report, name: 'Run Report' })
				}
			>
				<div>
					<img src={report} width="auto" height="auto" />
				</div>
			</Modal>
			<ModalLoading onView={loading} />
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={alert.visible}
				onClose={() => setAlert({ ...alert, visible: false })}
			>
				<MuiAlert
					elevation={6}
					severity={alert.severity}
					variant="filled"
					onClose={() => setAlert({ ...alert, visible: false })}
					sx={{ width: '100%' }}
				>
					{alert.message}
				</MuiAlert>
			</Snackbar>
		</div>
	);
};

export default RunReport;
