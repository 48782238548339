/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

// Material components
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import TimePicker from '@mui/lab/TimePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TablePagination from '@mui/material/TablePagination';

// Components
import Modal from '../../components/Modal';
import ModalLoading from '../../components/ModalLoading';

// Resources
import { IHourly } from '../../interfaces/hourly';
import { IAlert } from '../../interfaces/alert';
import { useGroup } from '../../hooks/useGroup';
import { useShift } from '../../hooks/useShift';
import { useHole } from '../../hooks/useHole';
import { createReport } from '../../api/reports';
import { getHourlyReports, hourlyAPI } from '../../api/hourly';

// Assets
import { useStyles } from './styles';
import { downloadImage } from '../../utils';

const HourlyReport = () => {
	const [hourly, setHourly] = useState<IHourly>({} as IHourly);
	const [modalForm, setModalForm] = useState(false);
	const [loading, setLoading] = useState(false);
	const [modalReport, setModalReport] = useState(false);
	const [report, setReport] = useState('');
	const [hourlyArr, setHourlyArr] = useState<IHourly[]>([]);
	const [alert, setAlert] = useState<IAlert>({
		visible: false,
		message: '',
		severity: 'success',
	});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const { groups, group, setGroup } = useGroup();
	const { shifts, shift, setShift } = useShift();
	const { holes, hole, setHole } = useHole();
	const classes = useStyles();

	useEffect(() => {
		_getDataFromApi();
	}, []);

	useEffect(() => {
		setHourly({ ...hourly, total: hourly.to - hourly.from });
	}, [hourly.to, hourly.from]);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const _getDataFromApi = (): void => {
		getHourlyReports({
			includes: [
				{
					model: 'group',
					attributes: ['id', 'name', 'image'],
				},
				{
					model: 'hole',
				},
			],
		}).then(res => {
			if (Array.isArray(res)) {
				setHourlyArr(res.reverse() || []);
			}
		});
	};

	const onPrintReport = (item: IHourly) => {
		const parseData: IHourly = {
			...item,
			from: Math.trunc(item.from * 100) / 100,
			to: Math.trunc(item.to * 100) / 100,
			total: Math.trunc(item.total * 100) / 100,
			rec: Math.trunc(item.rec * 100) / 100,
			time: moment(item.time).format('HH:mm'),
			date: moment(item.date).format('MM/DD/YYYY'),
		};
		const payload = {
			idReport: 24,
			format: 'image',
			payload: {
				Report: { ...parseData },
				Options: { Language: 'es' },
			},
		};
		setLoading(true);
		createReport<IHourly>(payload)
			.then(({ data }) => {
				setReport(
					`data:image/png;headers=filename%3D${
						item.date || 'download_file'
					};base64,${escape(data)}` || '',
				);
				setModalReport(true);
			})
			.finally(() => setLoading(false));
	};

	const _handleSaveHourly = (): void => {
		const hourlyFlag: IHourly = {
			...hourly,
			shift,
			time: hourly.time
				? moment(hourly.time).format('MM/DD/YYYY HH:mm:ss')
				: moment().format('MM/DD/YYYY HH:mm:ss'),
			date: hourly.date || moment().format('MM/DD/YYYY'),
			group_id: group.id,
			hole_id: hole.id,
		};

		setLoading(true);
		hourlyAPI
			.post('', hourlyFlag)
			.then(res => {
				console.log({ res });
				if (res) {
					setAlert({
						visible: true,
						severity: 'success',
						message: 'The hourly report has been saved successfully',
					});
					setModalForm(false);
					setHourly({} as IHourly);
					_getDataFromApi();
				}
			})
			.catch(() => {
				setAlert({
					visible: true,
					severity: 'error',
					message: 'Error, the hourly report has not been saved',
				});
			})
			.finally(() => setLoading(false));
	};

	const _handleCleanData = (): void => {
		setModalForm(false);
		setHourly({} as IHourly);
	};

	return (
		<div className={classes.root}>
			<div className={classes.containerButtonAdd}>
				<FormControl fullWidth className={classes.padding5}>
					<InputLabel id="group">Groups</InputLabel>
					<Select
						labelId="group"
						id="group"
						value={group.id || ''}
						label="Group"
						onChange={e => setGroup(e.target.value)}
					>
						{(groups || []).map((item, index) => (
							<MenuItem key={index} value={item.id}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth className={classes.padding5}>
					<InputLabel id="shift">Shifts</InputLabel>
					<Select
						labelId="shift"
						id="shift"
						value={shift || ''}
						label="Shift"
						onChange={e => setShift(e.target.value)}
					>
						{(shifts || []).map((item, index) => (
							<MenuItem key={index} value={item}>
								{item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth className={classes.padding5}>
					<InputLabel id="hole">Holes</InputLabel>
					<Select
						labelId="hole"
						id="hole"
						value={hole.id || ''}
						label="Hole"
						onChange={e => setHole(e.target.value)}
					>
						{(holes || []).map((item, index) => (
							<MenuItem key={index} value={item.id}>
								{item.hole_number}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			<div className={classes.containerButtonAdd}>
				<Button
					className={classes.btnAdd}
					variant="contained"
					onClick={() => setModalForm(true)}
				>
					Add Hourly
				</Button>
			</div>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead className={classes.headerTable}>
						<TableRow>
							<TableCell className={classes.headerTextTable}>Group</TableCell>
							<TableCell className={classes.headerTextTable}>Shift</TableCell>
							<TableCell className={classes.headerTextTable}>Time</TableCell>
							<TableCell className={classes.headerTextTable}>Date</TableCell>
							<TableCell
								size="small"
								className={classes.headerTextTableActions}
							>
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(hourlyArr || [])
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map(row => (
								<TableRow
									key={row.id}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell align="left">{row.group?.name}</TableCell>
									<TableCell align="left">{row.shift}</TableCell>
									<TableCell align="left">
										{moment(row.time).format('HH:mm')}
									</TableCell>
									<TableCell align="left">
										{moment(row.date).format('MM/DD/YYYY')}
									</TableCell>
									<TableCell align="left">
										<IconButton
											size="large"
											edge="start"
											color="inherit"
											aria-label="delete"
											sx={{ mr: 2 }}
											onClick={() => onPrintReport(row)}
										>
											<PrintIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[20, 50, 100]}
				component="div"
				count={hourlyArr.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			<Modal
				onView={modalForm}
				title="Hourly Form"
				onClose={_handleCleanData}
				onSave={_handleSaveHourly}
			>
				<Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item xs={4}>
						<Typography variant="h6">Group</Typography>
						<Typography variant="subtitle1" color="primary">
							{group?.name}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="h6">Shift</Typography>
						<Typography variant="subtitle1" color="primary">
							{shift}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="h6">Hole Number</Typography>
						<Typography variant="subtitle1" color="primary">
							{hole?.hole_number}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<DesktopDatePicker
							label="Date"
							inputFormat="MM/DD/YYYY"
							value={hourly.date || new Date()}
							onChange={e =>
								setHourly({ ...hourly, date: moment(e).format('MM/DD/YYYY') })
							}
							renderInput={(params: any) => (
								<TextField fullWidth variant="standard" {...params} />
							)}
						/>
					</Grid>
					<Grid item xs={6}>
						<TimePicker
							label="Time"
							value={hourly.time || new Date()}
							onChange={e => setHourly({ ...hourly, time: e })}
							renderInput={(params: any) => (
								<TextField fullWidth variant="standard" {...params} />
							)}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="from"
							label="From"
							className={classes.textField}
							variant="standard"
							type="number"
							fullWidth
							value={hourly.from}
							onChange={e =>
								setHourly({ ...hourly, from: parseFloat(e.target.value) })
							}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="to"
							label="To"
							className={classes.textField}
							variant="standard"
							type="number"
							fullWidth
							value={hourly.to}
							onChange={e =>
								setHourly({ ...hourly, to: parseFloat(e.target.value) })
							}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="total"
							label="Total"
							className={classes.textField}
							variant="standard"
							type="number"
							fullWidth
							disabled
							value={hourly.total}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="rec"
							label="Rec."
							className={classes.textField}
							variant="standard"
							type="number"
							fullWidth
							value={hourly.rec}
							onChange={e =>
								setHourly({ ...hourly, rec: parseFloat(e.target.value) })
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="comments"
							label="Comments"
							className={classes.textFieldComments}
							variant="standard"
							fullWidth
							multiline
							value={hourly.comments || ''}
							onChange={e => setHourly({ ...hourly, comments: e.target.value })}
						/>
					</Grid>
				</Grid>
			</Modal>
			<Modal
				onView={modalReport}
				title="Hourly Report"
				width="lg"
				textCancel="Close"
				onClose={() => setModalReport(false)}
				onDownloadReport={() =>
					downloadImage({ payload: report, name: 'Hourly Report' })
				}
			>
				<div>
					<img src={report} width="auto" height="auto" />
				</div>
			</Modal>
			<ModalLoading onView={loading} />
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={alert.visible}
				onClose={() => setAlert({ ...alert, visible: false })}
			>
				<MuiAlert
					elevation={6}
					severity={alert.severity}
					variant="filled"
					onClose={() => setAlert({ ...alert, visible: false })}
					sx={{ width: '100%' }}
				>
					{alert.message}
				</MuiAlert>
			</Snackbar>
		</div>
	);
};

export default HourlyReport;
