import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexGrow: 1,
			flexDirection: 'column',
		},
		containerButtonAdd: {
			width: '100%',
			display: 'flex',
			justifyContent: 'flex-end',
			flexDirection: 'row',
			marginBottom: 20,
		},
		btnAdd: {
			height: 50,
			width: 150,
			marginLeft: '15px !important',
		},
		textField: {
			height: 50,
		},
		headerTable: {
			backgroundColor: theme.palette.secondary.dark,
		},
		headerTextTable: {
			color: '#FFFFFF !important',
		},
		headerTextTableActions: {
			width: '10%',
			color: '#FFFFFF !important',
		},
		padding5: {
			padding: '5px !important',
		},
	}),
);
