import { IRunkeys } from '../interfaces/runkeys';

export const config = {
	staticValues: {
		margentic_declination: 9.65,
	},
	routes: [
		{
			route: 'hourly-report',
			icon: 'folder',
			name: 'Hourly Report',
		},
		{
			route: 'run-report',
			icon: 'folder',
			name: 'Run Report',
		},
		{
			route: 'core-report',
			icon: 'folder',
			name: 'Core Report',
		},
		{
			route: 'survey-report',
			icon: 'folder',
			name: 'Survey Report',
		},
		{
			route: 'settings',
			icon: 'settings',
			name: 'Settings',
		},
	],
	shifts: ['Day', 'Night'],
	runKeys: <IRunkeys>{
		es: {
			avance: 'shift_production',
			distancia: 'distance_drilled',
			'nucleo recuperado': 'core_recovered',
			'tipo de roca': 'core_type',
			'profundidad de barreno': 'hole_depth',
			'mezcla de aditivos': 'additives_mix',
			'prueba de presion maxima del agua': 'maximum_water',
			'prueba de flujo de agua': 'water_flow',
			'presion de agua perforando': 'water_pressure',
			'tubo interior, suelto o con el pescador': 'inner_tube',
			'tiempo para que llegue el interior al barril': 'time_inner_tube',
			'presion de agua en el aire': 'water_pressure_drilling',
			'retorno de agua': 'water_return',
			rotacion: 'drilling_rotation',
			'torque de rotacion en el aire': 'rotation_torque_bottom',
			'torque de rotacion perforando': 'rotation_torque_drilling',
			'tiempo de perforar la corrida': 'time_drill',
			'tiempo total de la corrida': 'total_time',
			'temp de motor': 'temp_engine',
			'temp bomba hid': 'temp_hydro',
			'temp caja de engranes': 'temp_gear',
			empuje: 'rotation_unit',
			'termino la corrida por?': 'run_finished',
			'nucleo en el barreno o barril?': 'slipped_core',
		},
		en: {
			'shift production': 'shift_production',
			'distance drilled': 'distance_drilled',
			'core recovered': 'core_recovered',
			'core type': 'core_type',
			'hole depth': 'hole_depth',
			'additives mix': 'additives_mix',
			'maximum water pressure test': 'maximum_water',
			'water flow test': 'water_flow',
			'water pressure while drilling': 'water_pressure',
			'inner tube lowered or dropped': 'inner_tube',
			'time for inner tube to reach the barrel': 'time_inner_tube',
			'water pressure before drilling': 'water_pressure_drilling',
			'water return out of hole': 'water_return',
			'drilling rotation in high or low': 'drilling_rotation',
			'rotation torque off of bottom': 'rotation_torque_bottom',
			'rotation torque while drilling': 'rotation_torque_drilling',
			'time to drill the run': 'time_drill',
			'total time for the drill run': 'total_time',
			'temp engine': 'temp_engine',
			'temp hyd pump': 'temp_hydro',
			'temp gear box': 'temp_gear',
			'rotation unit push pressure': 'rotation_unit',
			'why the run finished': 'run_finished',
			'slipped core in the hole or barrel': 'slipped_core',
		},
	},
};
