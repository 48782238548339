/* eslint-disable no-undef */
import React, { useState } from 'react';

// Material components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';

// Resources
import { useAuth } from '../../hooks/useAuth';

// Assets
import { useStyles } from './styles';

interface INavBar {
	title: string;
}

export default function NavBar(props: INavBar) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const classes = useStyles();
	const { user, logout } = useAuth();
	const { title } = props;

	const _handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const _handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div className={classes.root}>
			<AppBar position="fixed">
				<Toolbar>
					{/* <IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						sx={{ mr: 2 }}
					>
						<MenuIcon />
					</IconButton> */}
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
						{title}
					</Typography>
					<Button
						onClick={_handleMenu}
						color="inherit"
					>{`${user.first_name} ${user.last_name}`}</Button>
					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorEl)}
						onClose={_handleClose}
					>
						<MenuItem onClick={() => logout()}>Logout</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>
		</div>
	);
}
