import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			padding: 20,
			backgroundColor: theme.palette.primary.main,
		},
	}),
);
