import { IHole } from '../interfaces/hole';
import API from './base';

export const holesAPI = new API('/holes');

export const getHoles = (params?: any): Promise<IHole[]> => {
	return holesAPI
		.post<null, IHole[]>('search', params)
		.then(response => response);
};
