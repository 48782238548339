/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Resources
import { IUser } from '../interfaces/user';
import { login } from '../api/sessions';

export const useAuth = () => {
	const [user, setUser] = useState({} as IUser);
	const history = useHistory();

	useEffect(() => {
		const userStorage = localStorage.getItem('user');
		if (userStorage) {
			// history.push('/hourly-report');
			setUser(JSON.parse(userStorage));
		}
	}, []);

	const getUserLogged = (): boolean => {
		const userStorage = localStorage.getItem('user');
		return !!userStorage;
	};

	const setLogin = (userValue: IUser): Promise<any> => {
		return login({
			email: userValue.email,
			password: userValue.password || '',
		});
	};

	const logout = (): void => {
		localStorage.removeItem('user');
		localStorage.removeItem('token');
		history.push('/login');
	};

	return {
		setLogin,
		getUserLogged,
		logout,
		user,
	};
};
