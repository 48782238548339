import { ISurvey } from '../interfaces/survey';
import API from './base';

export const surveyAPI = new API('/surveys');

export const getSurveyReports = (params?: any): Promise<ISurvey[]> => {
	return surveyAPI
		.post<null, ISurvey[]>('search', params)
		.then(response => response);
};
