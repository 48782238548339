import React, { useState, useEffect } from 'react';

// Material components
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// Components
import Modal from '../../components/Modal';

// Resources
import { IUser } from '../../interfaces/user';
import { IAlert } from '../../interfaces/alert';
import { getUsers, userAPI } from '../../api/users';

// Assets
import { useStyles } from './styles';

const Users = () => {
	const [user, setUser] = useState<IUser>({} as IUser);
	const [modalForm, setModalForm] = useState(false);
	const [users, setUsers] = useState<IUser[]>([]);
	const [edit, setEdit] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		visible: false,
		message: '',
		severity: 'success',
	});
	const classes = useStyles();

	// const _handleSaveGroup = (): void => {
	// 	console.log(hole);
	// };

	useEffect(() => {
		_getDataFromApi();
	}, []);

	const _getDataFromApi = (): void => {
		getUsers().then(res => {
			setUsers(res.reverse() || []);
		});
	};

	const _handleSaveHole = (): void => {
		if (!user.email) {
			return setAlert({
				visible: true,
				severity: 'warning',
				message: 'Please add an email to the user',
			});
		}

		if (!edit && !user.password) {
			return setAlert({
				visible: true,
				severity: 'warning',
				message: 'Please add a password to the user',
			});
		}

		let promise: Promise<IUser>;

		if (edit) {
			promise = userAPI.put(user.id, user);
		} else {
			promise = userAPI.post('', user);
		}

		promise
			.then(res => {
				if (res) {
					setAlert({
						visible: true,
						severity: 'success',
						message: 'The user has been saved successfully',
					});
					if (edit) {
						const userFlag = localStorage.getItem('user');
						const userParsed = userFlag ? JSON.parse(userFlag) : ({} as IUser);
						if (userParsed.id === user.id) {
							localStorage.setItem('user', JSON.stringify(user));
						}
					}
					_getDataFromApi();
					setModalForm(false);
					setUser({} as IUser);
					setEdit(false);
				}
			})
			.catch(() => {
				setAlert({
					visible: true,
					severity: 'error',
					message: 'Error, the user has not been saved',
				});
			});
	};

	return (
		<div className={classes.containerGroup}>
			<div className={classes.containerButtonAdd}>
				<Button
					className={classes.btnAdd}
					variant="contained"
					onClick={() => setModalForm(true)}
				>
					Add User
				</Button>
			</div>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead className={classes.headerTable}>
						<TableRow>
							<TableCell className={classes.headerTextTable}>Name</TableCell>
							<TableCell className={classes.headerTextTable}>Email</TableCell>
							<TableCell className={classes.headerTextTable}>
								User name
							</TableCell>
							<TableCell className={classes.headerTextTable}>Phone</TableCell>
							<TableCell
								size="small"
								className={classes.headerTextTableActions}
							>
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(users || []).map(row => (
							<TableRow
								key={row.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell align="left">{`${row.first_name} ${row.last_name}`}</TableCell>
								<TableCell align="left">{row.email}</TableCell>
								<TableCell align="left">{row.username}</TableCell>
								<TableCell align="left">{row.phone}</TableCell>
								<TableCell align="left">
									<IconButton
										size="large"
										edge="start"
										color="inherit"
										aria-label="edit"
										sx={{ mr: 2 }}
										onClick={() => {
											setUser({ ...row });
											setEdit(true);
											setModalForm(true);
										}}
									>
										<EditIcon />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Modal
				onView={modalForm}
				title="User Form"
				onClose={() => {
					setModalForm(false);
					setUser({} as IUser);
					setEdit(false);
				}}
				onSave={_handleSaveHole}
			>
				<Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item xs={6}>
						<TextField
							id="first_name"
							label="First Name"
							className={classes.textField}
							variant="standard"
							fullWidth
							value={user.first_name || ''}
							onChange={e => setUser({ ...user, first_name: e.target.value })}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="last_name"
							label="Last Name"
							className={classes.textField}
							variant="standard"
							fullWidth
							value={user.last_name || ''}
							onChange={e => setUser({ ...user, last_name: e.target.value })}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="email"
							label="Email"
							className={classes.textField}
							variant="standard"
							fullWidth
							value={user.email || ''}
							onChange={e => setUser({ ...user, email: e.target.value })}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="phone"
							label="Phone"
							className={classes.textField}
							variant="standard"
							fullWidth
							type="number"
							value={user.phone || ''}
							onChange={e => {
								if (e.target.value.length <= 10) {
									setUser({ ...user, phone: e.target.value });
								}
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="username"
							label="Username"
							className={classes.textField}
							variant="standard"
							fullWidth
							value={user.username || ''}
							onChange={e => setUser({ ...user, username: e.target.value })}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="password"
							label="Password"
							className={classes.textField}
							variant="standard"
							fullWidth
							type="password"
							value={user.password || ''}
							onChange={e => setUser({ ...user, password: e.target.value })}
						/>
					</Grid>
				</Grid>
			</Modal>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={alert.visible}
				onClose={() => setAlert({ ...alert, visible: false })}
			>
				<MuiAlert
					elevation={6}
					severity={alert.severity}
					variant="filled"
					onClose={() => setAlert({ ...alert, visible: false })}
					sx={{ width: '100%' }}
				>
					{alert.message}
				</MuiAlert>
			</Snackbar>
		</div>
	);
};

export default Users;
