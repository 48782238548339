import React, { useState, useEffect } from 'react';

// Material components
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// Components
import Modal from '../../components/Modal';

// Resources
import { IHole } from '../../interfaces/hole';
import { IStation } from '../../interfaces/station';
import { IAlert } from '../../interfaces/alert';
import { getStations } from '../../api/stations';
import { getHoles, holesAPI } from '../../api/holes';

// Assets
import { useStyles } from './styles';

const Holes = () => {
	const [hole, setHole] = useState<IHole>({} as IHole);
	const [modalForm, setModalForm] = useState(false);
	const [holes, setHoles] = useState<IHole[]>([]);
	const [stations, setStations] = useState<IStation[]>([]);
	const [station, setStationState] = useState<IStation>({} as IStation);
	const [edit, setEdit] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		visible: false,
		message: '',
		severity: 'success',
	});
	const classes = useStyles();

	useEffect(() => {
		_getDataFromApi();
		getStations().then(res => {
			setStations(res.reverse() || []);
		});
	}, []);

	const _getDataFromApi = (): void => {
		getHoles({
			includes: [
				{
					model: 'station',
					attributes: ['id', 'name'],
				},
			],
		}).then(res => {
			setHoles(res || []);
		});
	};

	const _handleSaveHole = (): void => {
		if (!hole.hole_number) {
			return setAlert({
				visible: true,
				severity: 'warning',
				message: 'Please add a hole number to the hole',
			});
		}

		if (!station.id) {
			return setAlert({
				visible: true,
				severity: 'warning',
				message: 'Please select a station',
			});
		}

		let promise: Promise<IHole>;

		if (edit) {
			promise = holesAPI.put(hole.id, hole);
		} else {
			promise = holesAPI.post('', hole);
		}

		promise
			.then(res => {
				if (res) {
					setAlert({
						visible: true,
						severity: 'success',
						message: 'The hole has been saved successfully',
					});
					if (edit) {
						const holeFlag = localStorage.getItem('hole');
						const holeParsed = holeFlag ? JSON.parse(holeFlag) : ({} as IHole);
						if (holeParsed.id === hole.id) {
							localStorage.setItem('hole', JSON.stringify(hole));
						}
					}
					_getDataFromApi();
					setModalForm(false);
					setHole({} as IHole);
					setEdit(false);
					setStationState({} as IStation);
				}
			})
			.catch(() => {
				setAlert({
					visible: true,
					severity: 'error',
					message: 'Error, the hole has not been saved',
				});
			});
	};

	const setStation = (stationID: string | number): void => {
		const stationV: IStation =
			stations.find(e => e.id === stationID) || ({} as IStation);
		setHole({ ...hole, station_id: stationV.id });
		setStationState(stationV);
	};

	return (
		<div className={classes.containerGroup}>
			<div className={classes.containerButtonAdd}>
				<Button
					className={classes.btnAdd}
					variant="contained"
					onClick={() => setModalForm(true)}
				>
					Add Hole
				</Button>
			</div>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead className={classes.headerTable}>
						<TableRow>
							<TableCell className={classes.headerTextTable}>
								Hole Number
							</TableCell>
							<TableCell className={classes.headerTextTable}>Azimuth</TableCell>
							<TableCell className={classes.headerTextTable}>
								Total Drilling
							</TableCell>
							<TableCell className={classes.headerTextTable}>Type</TableCell>
							<TableCell className={classes.headerTextTable}>Angle</TableCell>
							<TableCell className={classes.headerTextTable}>Size</TableCell>
							<TableCell className={classes.headerTextTable}>Station</TableCell>
							<TableCell
								size="small"
								className={classes.headerTextTableActions}
							>
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{holes.map(row => (
							<TableRow
								key={row.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell align="left">{row.hole_number}</TableCell>
								<TableCell align="left">{row.azimuth}</TableCell>
								<TableCell align="left">{row.total_drilling}</TableCell>
								<TableCell align="left">{row.type}</TableCell>
								<TableCell align="left">{row.angle}</TableCell>
								<TableCell align="left">{row.size}</TableCell>
								<TableCell align="left">{row.station?.name}</TableCell>
								<TableCell align="left">
									<IconButton
										size="large"
										edge="start"
										color="inherit"
										aria-label="menu"
										sx={{ mr: 2 }}
										onClick={() => {
											setHole({ ...row });
											setEdit(true);
											setStationState({ ...row.station });
											setModalForm(true);
										}}
									>
										<EditIcon />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Modal
				onView={modalForm}
				title="Hole Form"
				onClose={() => setModalForm(false)}
				onSave={_handleSaveHole}
			>
				<Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item xs={6}>
						<TextField
							id="hole_number"
							label="Hole Number"
							className={classes.textField}
							variant="standard"
							fullWidth
							value={hole.hole_number || ''}
							onChange={e => setHole({ ...hole, hole_number: e.target.value })}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="azimuth"
							label="Azimuth"
							className={classes.textField}
							variant="standard"
							fullWidth
							value={hole.azimuth || ''}
							onChange={e => setHole({ ...hole, azimuth: e.target.value })}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="total_drilling"
							label="Total Drilling"
							className={classes.textField}
							variant="standard"
							fullWidth
							value={hole.total_drilling || ''}
							onChange={e =>
								setHole({ ...hole, total_drilling: e.target.value })
							}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="type"
							label="Type"
							className={classes.textField}
							variant="standard"
							fullWidth
							value={hole.type || ''}
							onChange={e => setHole({ ...hole, type: e.target.value })}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="angle"
							label="Angle"
							className={classes.textField}
							variant="standard"
							fullWidth
							value={hole.angle || ''}
							onChange={e => setHole({ ...hole, angle: e.target.value })}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="size"
							label="Size"
							className={classes.textField}
							variant="standard"
							fullWidth
							value={hole.size || ''}
							onChange={e => setHole({ ...hole, size: e.target.value })}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormControl fullWidth>
							<InputLabel id="hole">Station</InputLabel>
							<Select
								labelId="station"
								id="station"
								value={station.id || 0}
								label="Station"
								onChange={e => setStation(e.target.value)}
							>
								{(stations || []).map((item, index) => (
									<MenuItem key={index} value={item.id}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Modal>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={alert.visible}
				onClose={() => setAlert({ ...alert, visible: false })}
			>
				<MuiAlert
					elevation={6}
					severity={alert.severity}
					variant="filled"
					onClose={() => setAlert({ ...alert, visible: false })}
					sx={{ width: '100%' }}
				>
					{alert.message}
				</MuiAlert>
			</Snackbar>
		</div>
	);
};

export default Holes;
