import { useState, useEffect } from 'react';
import { config } from '../config';

export const useShift = () => {
	const [shifts] = useState(config.shifts);
	const [shift, setShiftState] = useState<string>('Day');

	useEffect(() => {
		const shiftFlag = localStorage.getItem('shift');
		setShiftState(shiftFlag ? JSON.parse(shiftFlag) : '');
	}, []);

	const setShift = (shiftName: string | number): void => {
		const shiftV: string = shifts.find(e => e === shiftName) || '';
		localStorage.setItem('shift', JSON.stringify(shiftV));
		setShiftState(shiftV);
	};

	return {
		setShift,
		shift,
		shifts,
	};
};
