import React from 'react';

// Material components

// Components
import Tabs from '../../components/Tabs';
import Groups from './groups';
import Holes from './holes';
import Stations from './stations';
import Users from './users';

// Assets
import { useStyles } from './styles';

const Settings = () => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Tabs
				tabs={[
					{
						name: 'Groups',
						component: <Groups />,
					},
					{
						name: 'Stations',
						component: <Stations />,
					},
					{
						name: 'Holes',
						component: <Holes />,
					},
					{
						name: 'Users',
						component: <Users />,
					},
				]}
			/>
		</div>
	);
};

export default Settings;
