import API from './base';

export const reportsAPI = new API(
	'/report',
	process.env.REACT_APP_FR_API_URL,
	true,
);

export type IReport<T> = {
	idReport: number;
	format?: string;
	payload: {
		Options: { Language: string };
		Report: T;
	};
};

export type IResponseReport = {
	data: string;
};

export const createReport = <TypeReport>(
	data: IReport<TypeReport>,
): Promise<IResponseReport> => {
	return reportsAPI
		.post<IReport<TypeReport>, IResponseReport>('', data)
		.then(response => response);
};
