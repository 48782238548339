import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//Components
import HourlyReport from '../views/HourlyReport';
import RunReport from '../views/RunReport';
import Settings from '../views/Settings';
import SurveyReport from '../views/SurveyReport';
import CoreReport from '../views/CoreReport';
import Login from '../views/Login';
import DrawerPersist from '../components/Drawer';

export const App = () => {
	return (
		<Router>
			<Switch>
				<Route path="/login" component={Login} />
				<DrawerPersist>
					{/* <Route
							path="/"
							render={props => {
								if (!isLogin) {
									return (
										<Redirect
											to={{
												pathname: '/login',
												state: { from: props.location },
											}}
										/>
									);
								}
							}}
						/> */}
					{/* <Route
							path="/login"
							component={Login}
							render={props => {
								if (isLogin) {
									return (
										<Redirect
											to={{
												pathname: '/hourly-report',
												state: { from: props.location },
											}}
										/>
									);
								}
							}}
						/> */}
					<Fragment>
						<Route path="/hourly-report" component={HourlyReport} />
						<Route path="/run-report" component={RunReport} />
						<Route path="/core-report" component={CoreReport} />
						<Route path="/settings" component={Settings} />
						<Route path="/survey-report" component={SurveyReport} />
					</Fragment>
				</DrawerPersist>
			</Switch>
		</Router>
	);
};
