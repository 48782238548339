import React from 'react';

// Material components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Resources

// Assets
import { useStyles } from './styles';

interface IModalLoadingProps {
	onView: boolean;
	width?: 'md' | 'lg' | 'xl' | 'sm' | 'xs';
}

const ModalLoading = (props: IModalLoadingProps) => {
	const classes = useStyles();

	const { onView, width } = props;
	return (
		<Dialog open={onView} maxWidth={width || 'xs'} fullWidth>
			<DialogContent className={classes.container}>
				<Box sx={{ width: '100%' }}>
					<Typography variant="h6">Loading...</Typography>
					<LinearProgress color="secondary" />
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default ModalLoading;
