/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

// Material components
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import TimePicker from '@mui/lab/TimePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TablePagination from '@mui/material/TablePagination';

// Components
import Modal from '../../components/Modal';
import ModalLoading from '../../components/ModalLoading';

// Resources
import { ICore } from '../../interfaces/core';
import { IAlert } from '../../interfaces/alert';
import { useGroup } from '../../hooks/useGroup';
import { useShift } from '../../hooks/useShift';
import { useHole } from '../../hooks/useHole';
import { createReport } from '../../api/reports';
import { getCoreReports, coreAPI } from '../../api/core';
import { downloadImage, uploadToFileServer } from '../../utils';

// Assets
import { useStyles } from './styles';

const CoreReport = () => {
	const [core, setCore] = useState<ICore>({} as ICore);
	const [modalForm, setModalForm] = useState(false);
	const [loading, setLoading] = useState(false);
	const [modalReport, setModalReport] = useState(false);
	const [edit, setEdit] = useState(false);
	const [report, setReport] = useState('');
	const [imgName, setImgName] = useState('');
	const [coreArr, setCoreArr] = useState<ICore[]>([]);
	const [alert, setAlert] = useState<IAlert>({
		visible: false,
		message: '',
		severity: 'success',
	});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const { groups, group, setGroup } = useGroup();
	const { shifts, shift, setShift } = useShift();
	const { holes, hole, setHole } = useHole();

	const classes = useStyles();

	useEffect(() => {
		_getDataFromApi();
	}, []);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const _getDataFromApi = (): void => {
		getCoreReports({
			includes: [
				{
					model: 'group',
					attributes: ['id', 'name', 'image'],
				},
				{
					model: 'hole',
				},
			],
		}).then(res => {
			if (Array.isArray(res)) {
				setCoreArr(res.reverse() || []);
			}
		});
	};

	const onPrintReport = (item: ICore) => {
		const payload = {
			idReport: 58,
			format: 'image',
			payload: {
				Report: {
					...item,
					date: moment(item.date).format('MM/DD/YYYY'),
					time: moment(item.time).format('HH:mm'),
				},
				Options: { Language: 'es' },
			},
		};
		setLoading(true);
		createReport<ICore>(payload)
			.then(({ data }) => {
				setReport(
					`data:image/png;headers=filename%3D${
						moment(item.date).format('MM/DD/YYYY') || 'download_file'
					};base64,${escape(data)}` || '',
				);
				setModalReport(true);
			})
			.finally(() => setLoading(false));
	};

	const _handleSaveCore = (): void => {
		if (!core.shift_production) {
			return setAlert({
				visible: true,
				severity: 'warning',
				message: 'Please complete the Distance drilled',
			});
		}

		if (!core.distance_drilled) {
			return setAlert({
				visible: true,
				severity: 'warning',
				message: 'Please complete the Core recovered',
			});
		}

		if (!core.image) {
			return setAlert({
				visible: true,
				severity: 'warning',
				message: 'Please add an image',
			});
		}

		const coreFlag: ICore = {
			...core,
			shift,
			time: core.time
				? moment(core.time).format('MM/DD/YYYY HH:mm:ss')
				: moment().format('MM/DD/YYYY HH:mm:ss'),
			date: core.date || moment().format('MM/DD/YYYY'),
			group_id: group.id,
			hole_id: hole.id,
		};

		let promise: Promise<ICore>;

		if (edit) {
			promise = coreAPI.put(core.id, coreFlag);
		} else {
			promise = coreAPI.post('', coreFlag);
		}

		setLoading(true);
		promise
			.then(res => {
				if (res) {
					setAlert({
						visible: true,
						severity: 'success',
						message: 'The core sample report has been saved successfully',
					});
					setModalForm(false);
					setEdit(false);
					setCore({} as ICore);
					setImgName('');
					_getDataFromApi();
				}
			})
			.catch(() => {
				setAlert({
					visible: true,
					severity: 'error',
					message: 'Error, the core sample report has not been saved',
				});
			})
			.finally(() => setLoading(false));
	};

	const onEdit = (row: ICore): void => {
		setImgName(row.image);
		setCore({ ...row });
		setModalForm(true);
		setEdit(true);
	};

	const _handleCleanData = (): void => {
		setEdit(false);
		setModalForm(false);
		setImgName('');
		setCore({} as ICore);
	};

	const _uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			const fileToAdd: File = event?.target?.files[0];
			uploadToFileServer(fileToAdd).then(file => {
				setImgName(fileToAdd.name || '');
				setCore({ ...core, image: file.url });
			});
		}
	};

	return (
		<div className={classes.root}>
			<div className={classes.containerButtonAdd}>
				<FormControl fullWidth className={classes.padding5}>
					<InputLabel id="group">Groups</InputLabel>
					<Select
						labelId="group"
						id="group"
						value={group.id || ''}
						label="Group"
						onChange={e => setGroup(e.target.value)}
					>
						{(groups || []).map((item, index) => (
							<MenuItem key={index} value={item.id}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth className={classes.padding5}>
					<InputLabel id="shift">Shifts</InputLabel>
					<Select
						labelId="shift"
						id="shift"
						value={shift || ''}
						label="Shift"
						onChange={e => setShift(e.target.value)}
					>
						{(shifts || []).map((item, index) => (
							<MenuItem key={index} value={item}>
								{item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth className={classes.padding5}>
					<InputLabel id="hole">Holes</InputLabel>
					<Select
						labelId="hole"
						id="hole"
						value={hole.id || ''}
						label="Hole"
						onChange={e => setHole(e.target.value)}
					>
						{(holes || []).map((item, index) => (
							<MenuItem key={index} value={item.id}>
								{item.hole_number}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			<div className={classes.containerButtonAdd}>
				<Button
					className={classes.btnAdd}
					variant="contained"
					onClick={() => setModalForm(true)}
				>
					Add Core
				</Button>
			</div>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead className={classes.headerTable}>
						<TableRow>
							<TableCell className={classes.headerTextTable}>Group</TableCell>
							<TableCell className={classes.headerTextTable}>Shift</TableCell>
							<TableCell className={classes.headerTextTable}>Time</TableCell>
							<TableCell className={classes.headerTextTable}>Date</TableCell>
							<TableCell
								size="small"
								className={classes.headerTextTableActions}
							>
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(coreArr || []).map(row => (
							<TableRow
								key={row.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell align="left">{row.group?.name}</TableCell>
								<TableCell align="left">{row.shift}</TableCell>
								<TableCell align="left">
									{moment(row.time).format('HH:mm')}
								</TableCell>
								<TableCell align="left">
									{moment(row.date).format('MM/DD/YYYY')}
								</TableCell>
								<TableCell align="left">
									<IconButton
										size="large"
										edge="start"
										color="inherit"
										aria-label="edit"
										sx={{ mr: 2 }}
										onClick={() => onEdit(row)}
									>
										<EditIcon />
									</IconButton>
									<IconButton
										size="large"
										edge="start"
										color="inherit"
										aria-label="print"
										sx={{ mr: 2 }}
										onClick={() => onPrintReport(row)}
									>
										<PrintIcon />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[20, 50, 100]}
				component="div"
				count={coreArr.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			<Modal
				onView={modalForm}
				title="Core Form"
				onClose={_handleCleanData}
				onSave={_handleSaveCore}
			>
				<Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item xs={4}>
						<Typography variant="h6">Group</Typography>
						<Typography variant="subtitle1" color="primary">
							{group?.name}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="h6">Shift</Typography>
						<Typography variant="subtitle1" color="primary">
							{shift}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="h6">Hole Number</Typography>
						<Typography variant="subtitle1" color="primary">
							{hole?.hole_number}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<DesktopDatePicker
							label="Date"
							inputFormat="MM/DD/YYYY"
							value={core.date || new Date()}
							onChange={e =>
								setCore({ ...core, date: moment(e).format('MM/DD/YYYY') })
							}
							renderInput={(params: any) => (
								<TextField fullWidth variant="standard" {...params} />
							)}
						/>
					</Grid>
					<Grid item xs={6}>
						<TimePicker
							label="Time"
							value={core.time || new Date()}
							onChange={e => setCore({ ...core, time: e })}
							renderInput={(params: any) => (
								<TextField fullWidth variant="standard" {...params} />
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							id="shift_production"
							label="Shift production"
							className={classes.textField}
							variant="standard"
							fullWidth
							value={core.shift_production || ''}
							onChange={e =>
								setCore({ ...core, shift_production: e.target.value })
							}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							id="distance_drilled"
							label="Distance drilled"
							className={classes.textField}
							variant="standard"
							fullWidth
							value={core.distance_drilled || ''}
							onChange={e =>
								setCore({ ...core, distance_drilled: e.target.value })
							}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							id="core_recovered"
							label="Core recovered"
							className={classes.textField}
							variant="standard"
							fullWidth
							value={core.core_recovered || ''}
							onChange={e =>
								setCore({ ...core, core_recovered: e.target.value })
							}
						/>
					</Grid>
					<Grid item xs={8}>
						<TextField
							id="file_name"
							label="File Name"
							className={classes.textField}
							variant="standard"
							fullWidth
							disabled
							value={imgName || ''}
						/>
					</Grid>
					<Grid item xs={4}>
						<Button
							variant="contained"
							className={classes.addImageGroup}
							component="label"
						>
							Upload File
							<input type="file" hidden onChange={_uploadFile} />
						</Button>
					</Grid>
				</Grid>
			</Modal>
			<Modal
				onView={modalReport}
				title="Core Report"
				width="md"
				textCancel="Close"
				onClose={() => setModalReport(false)}
				onDownloadReport={() =>
					downloadImage({ payload: report, name: 'Core Report' })
				}
			>
				<div>
					<img src={report} width="auto" height="auto" />
				</div>
			</Modal>
			<ModalLoading onView={loading} />
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={alert.visible}
				onClose={() => setAlert({ ...alert, visible: false })}
			>
				<MuiAlert
					elevation={6}
					severity={alert.severity}
					variant="filled"
					onClose={() => setAlert({ ...alert, visible: false })}
					sx={{ width: '100%' }}
				>
					{alert.message}
				</MuiAlert>
			</Snackbar>
		</div>
	);
};

export default CoreReport;
