import axios, {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosPromise,
	AxiosRequestHeaders,
} from 'axios';
const { Service } = require('axios-middleware');

interface RequestPayload {
	data: unknown;
	err?: string;
	errmsg?: string;
}

export default class API {
	baseURL: string | undefined;
	scheme: string;
	request: AxiosInstance;
	report: boolean;
	constructor(
		scheme: string,
		baseURL = process.env.REACT_APP_API_URL,
		report = false,
	) {
		this.baseURL = baseURL;
		this.scheme = scheme;
		this.report = report;
		this.request = axios.create({
			baseURL: this.baseURL,
			headers: {
				'Content-Type': 'application/json',
			},
		});

		const service = new Service(this.request);
		service.register({
			async onRequest(config: AxiosRequestConfig) {
				const configFlag = { ...config };
				if (configFlag?.headers?.Authorization) {
					return configFlag;
				} else {
					const token = localStorage.getItem('token');
					if (token) {
						configFlag.headers = {
							...configFlag.headers,
							Authorization: `Bearer ${JSON.parse(token || '')}`,
						};
					}
				}
				return configFlag;
			},
		});
	}

	requestWrapper<AxiosResponse>(
		req: AxiosPromise<any>,
	): Promise<AxiosResponse> {
		return req
			.then(({ data }) => {
				try {
					if (data.err) {
						throw Error(data.err);
					}
					if (this.report) {
						return data;
					}
					return data.data;
				} catch (error) {
					console.log({ error });
					throw error;
				}
			})
			.catch(err => {
				console.log({ err });
				if (err.response?.data?.error_message === 'Not logged.') {
					localStorage.removeItem('user');
					localStorage.removeItem('token');
				}
				throw err;
			});
	}

	get<ParamsType, AxiosResponse>(
		path?: string,
		params?: ParamsType,
	): Promise<AxiosResponse> {
		const url = path ? `${this.scheme}/${path}` : this.scheme;
		return this.requestWrapper<AxiosResponse>(
			this.request({ url, data: params }),
		);
	}

	post<ParamsType, AxiosResponse>(
		path: string,
		data?: ParamsType,
		headers?: AxiosRequestHeaders,
		cancelToken?: any,
	): Promise<AxiosResponse> {
		const url = path ? `${this.scheme}/${path}` : this.scheme;
		return this.requestWrapper<AxiosResponse>(
			this.request({
				url,
				data,
				method: 'POST',
				headers,
				cancelToken: cancelToken,
			}),
		);
	}

	put<ParamsType, AxiosResponse>(
		id: number | string,
		data?: ParamsType,
	): Promise<AxiosResponse> {
		const url = id ? `${this.scheme}/${id}` : this.scheme;
		return this.requestWrapper<AxiosResponse>(
			this.request({
				url,
				data,
				method: 'PUT',
			}),
		);
	}

	delete(id: number): Promise<RequestPayload> {
		return this.requestWrapper(
			this.request({ url: `${this.scheme}/${id}`, method: 'DELETE' }),
		);
	}

	show(id: number, params?: any): Promise<RequestPayload> {
		return this.requestWrapper(
			this.request({ url: `${this.scheme}/${id}`, params }),
		);
	}
}

export const baseRequest = new API('/');
