// Dependencies
import React from 'react';
import ReactDOM from 'react-dom';

// Components
import { App } from './router';
import reportWebVitals from './reportWebVitals';

// Material Components
import { ThemeProvider } from '@mui/material/styles';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

// Assets
import './assets/index.css';
import theme from './config/theme';

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={DateAdapter}>
				<App />
			</LocalizationProvider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
