import { IHourly } from '../interfaces/hourly';
import API from './base';

export const hourlyAPI = new API('/hourly');

export const getHourlyReports = (params?: any): Promise<IHourly[]> => {
	return hourlyAPI
		.post<null, IHourly[]>('search', params)
		.then(response => response);
};
