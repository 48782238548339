import { IUser } from '../interfaces/user';
import API from './base';

interface ILoginParams {
	email: string;
	password: string;
}

interface IResponseLogin {
	user: IUser;
	Authorization: string;
	error_message?: string;
}

export const sessionAPI = new API(
	'/sessions/login',
	process.env.REACT_APP_API_URL,
	true,
);

export const login = (params: ILoginParams): Promise<IResponseLogin> => {
	return sessionAPI
		.post<ILoginParams, IResponseLogin>('', params)
		.then(response => response);
};
