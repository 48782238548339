import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			height: '100%',
		},
		containerTab: {
			width: '100%',
			height: 'auto',
			borderBottomColor: theme.palette.primary.main,
			borderBottomStyle: 'solid',
			borderBottomWidth: 2,
			paddingTop: 15,
			paddingBottom: 15,
		},
		containerComponent: {
			paddingTop: 15,
		},
		buttonsMargin: {
			marginRight: '10px !important',
		},
	}),
);
