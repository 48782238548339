import { useState, useEffect } from 'react';

// Resources
import { IGroup } from '../interfaces/group';
import { getGroups } from '../api/groups';

export const useGroup = () => {
	const [groups, setGroups] = useState<IGroup[]>([]);
	const [group, setGroupState] = useState<IGroup>({} as IGroup);

	useEffect(() => {
		const groupFlag = localStorage.getItem('group');
		getGroups().then(res => {
			setGroups(res || []);
			setGroupState(groupFlag ? JSON.parse(groupFlag) : {});
		});
	}, []);

	const setGroup = (groupID: string | number): void => {
		console.log({ groupID });
		const groupV: IGroup = groups.find(e => e.id === groupID) || ({} as IGroup);
		localStorage.setItem('group', JSON.stringify(groupV));
		setGroupState(groupV);
	};

	return {
		setGroup,
		group,
		groups,
	};
};
