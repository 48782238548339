import { IStation } from '../interfaces/station';
import API from './base';

export const stationsAPI = new API('/stations');

export const getStations = (): Promise<IStation[]> => {
	return stationsAPI
		.post<null, IStation[]>('search')
		.then(response => response);
};
