import { ICore } from '../interfaces/core';
import API from './base';

export const coreAPI = new API('/cores');

export const getCoreReports = (params?: any): Promise<ICore[]> => {
	return coreAPI
		.post<null, ICore[]>('search', params)
		.then(response => response);
};
